@layer base {
  html, body {
    font-family: "Lato", sans-serif;
  }

  h1, h2, h3, .heading-font, .nav-button, .j-modal-header {
    /*font-family:"Baloo Bhai 2", sans-serif;*/
  }

  @font-face {
    font-family: 'Baloo Bhai 2';
    src: url('/fonts/BalooBhai2/BalooBhai2-ExtraBold.woff2') format('woff2'),
    url('/fonts/BalooBhai2/BalooBhai2-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Baloo Bhai 2';
    src: url('/fonts/BalooBhai2/BalooBhai2-SemiBold.woff2') format('woff2'),
    url('/fonts/BalooBhai2/BalooBhai2-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Baloo Bhai 2';
    src: url('/fonts/BalooBhai2/BalooBhai2-Bold.woff2') format('woff2'),
    url('/fonts/BalooBhai2/BalooBhai2-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Baloo Bhai 2';
    src: url('/fonts/BalooBhai2/BalooBhai2-Regular.woff2') format('woff2'),
    url('/fonts/BalooBhai2/BalooBhai2-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Baloo Bhai 2';
    src: url('/fonts/BalooBhai2/BalooBhai2-Medium.woff2') format('woff2'),
    url('/fonts/BalooBhai2/BalooBhai2-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Regular.ttf') format('ttf'),
    url('/fonts/Lato/Lato-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

