/*noinspection CssUnknownTarget */
@import "journey-ui/styles/index.css";
@import "vars.css";
@import "font.css";
@import "components.pcss";

.j-content-bg-overlay {
  background: rgba(0,0,0,.25);
}

.animate-bg-pulse {
  @apply absolute inset-0 w-full h-full from-transparent to-primary bg-gradient-to-tr animate-pulse-fast m-0 !important;
}

.j-select-button-caret {
  @apply w-[16px] h-[16px]
}

.report-flush-btn {
  @apply flex-grow border-0 rounded-none py-6 w-full lg:w-auto
}

.row-item:nth-child(even)  {
  @apply bg-gray-100
}

.card-row:last-child {
  @apply border-b-0 !important;
}

.edit-table {
  @apply space-y-1 overflow-x-auto flex flex-col
}

.edit-table-headers {
  @apply flex bg-gray-100 py-2 z-50 w-full
}

.edit-table-headers .edit-table-cell {
  @apply font-bold
}

.edit-table-row {
  @apply flex flex-shrink-0 border-b border-dashed border-gray-300 py-2 flex-grow
}

.edit-table-cell {
  @apply flex-shrink-0 flex justify-center items-center flex-col space-y-2 min-w-[150px] overflow-hidden;
}


.edit-table-cell.name-cell {
  @apply justify-start px-1 flex-row space-y-0 space-x-2;
}

.primary-cell {
  @apply w-[200px];
}

.login-page .j-content {
  @apply p-0
}

.j-content {
  @apply p-0 justify-start;
}

.j-page .rdt_TableHeader {
  @apply flex-grow-0;
}

.action-button {
  @apply flex-grow text-center justify-center shadow uppercase h-12 w-full sm:w-auto;
  background-color: #f8f8f8;
}

.order-page .j-content {
  @apply p-2
}

.j-modal {
  max-height: 90%;
}

.j-page {
  @apply flex-nowrap flex-grow flex-col sm:flex-row;

/*  !* Due to the AppPage having two flex children, on older android webviews, the smallest flex height becomes the height of the bigger flex child. This causes an
  overflow issue as the actual height wasnt being accurate accounted. So the solution is to put the overflow on the troublesome flex child (.content-container) and make page overflow visible. *!
  @apply overflow-visible;*/
}

.chat-page .content-container {
  @apply overflow-visible
}

.content-container {
  /* pb-[45px] is so the content doesnt hide behind the appfooter*/
  @apply flex flex-col flex-nowrap flex-grow overflow-y-auto pb-[45px] sm:pb-0;
}

.j-card {
  @apply shadow p-3 space-y-2
}

.order-tabs .j-tab-button {
  @apply flex-grow-0 px-8
}

.card-header {
  @apply font-bold heading-font text-2xl
}

.order-tabs .j-tab-button-content {
  @apply  justify-start text-left
}

.order-tabs .j-tab-button {
  @apply shadow
}

.card-title {
  @apply flex items-center space-x-2;
}

.menu-page .j-content {
  @apply p-2
}

.spinner-dots {
  margin: 0 auto 0;
  text-align: center;
}

.spinner-dots > div {
  @apply rounded-full border-4 border-primary bg-white;
  margin-left: 1px;
  margin-right: 1px;
  width: 17px;
  height: 17px;

  display: inline-block;
  -webkit-animation: sk-bouncedelay 0.6s infinite ease-in-out both;
  animation: sk-bouncedelay 0.6s infinite ease-in-out both;
}

.spinner-dots .bounce1 {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.spinner-dots .bounce2 {
  -webkit-animation-delay: -0.09s;
  animation-delay: -0.09s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.3) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
