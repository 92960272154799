@layer components {
  .j-tab-buttons.sleek {
    @apply space-x-0;

    .j-tab-button {
      @apply border-b border-0 rounded-none border-solid rounded-tr rounded-tl;
    }

    .j-tab-button.active {
      @apply border-b-0 border-t border-r border-l
    }
  }

  .login-frame {
    @apply relative w-full h-full flex pt-4 sm:pt-0 sm:justify-center items-center flex-col;

    .login-panel {
      @apply block bg-white shadow-3xl text-left text-center w-full max-w-md rounded flex-grow-0 flex flex-col space-y-2 justify-center items-center z-50 h-80 relative;

      @screen md {
        width: 512px;
      }
    }
  }
}
