:root {
  --primary: 147,155,53;
  --primary-contrast: 245, 245, 245;
  --secondary: 135, 143, 49;
  --footer: 250, 250, 250;
  --body: 240, 240, 240;
  --body-hover: 240, 240, 240;
  --secondary-body: 250,250,250;
  --typography: 20, 20, 20;

  --input-background: 235,235,235;

  --scrollbar-background: 230,230,230;
  --scrollbar-radius: 0;
  --scrollbar-size: 6px;
  --scrollbar-inner-padding: 0;
  --spacing-popover: .5rem;
  --spacing-field: 1rem;
  --base-roundness: 4px;
}
